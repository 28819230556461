export const UPDATE_PRODUCT: string = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_ASYNC: string = 'UPDATE_PRODUCT_ASYNC';

export const CREATE_PRODUCT: string = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_ASYNC: string = 'CREATE_PRODUCT_ASYNC';



export const GET_PRODUCTS: string = 'GET_PRODUCTS';
export const GET_PRODUCTS_ASYNC: string = 'GET_PRODUCTS_ASYNC';

export const GET_PRODUCT: string = 'GET_PRODUCT';
export const GET_PRODUCT_ASYNC: string = 'GET_PRODUCT_ASYNC';

export const CLEAR_DATA_PRODUCTS: string = 'CLEAR_DATA_PRODUCTS';

export const SET_SELECTED_TIER = 'SET_SELECTED_TIER';
export const SET_SELECTED_TIER_ASYNC = 'SET_SELECTED_TIER_ASYNC';
export const CLEAR_SELECTED_TIER = 'CLEAR_SELECTED_TIER';
export const CREATE_CHECKOUT_SESSION_ASYNC:string = 'CREATE_CHECKOUT_SESSTION_ASYNC';
export const CREATE_CHECKOUT_SESSION:string = 'CREATE_CHECKOUT_SESSION';


export const CANCEL_SUBSCRIPTION: string = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_ASYNC: string = 'CANCEL_SUBSCRIPTION_ASYNC';


export const RESUME_SUBSCRIPTION: string = 'RESUME_SUBSCRIPTION';
export const RESUME_SUBSCRIPTION_ASYNC: string = 'RESUME_SUBSCRIPTION_ASYNC';

export const RENEW_SUBSCRIPTION: string = 'RENEW_SUBSCRIPTION';
export const RENEW_SUBSCRIPTION_ASYNC: string = 'RENEW_SUBSCRIPTION_ASYNC';


